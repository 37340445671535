import styled from 'styled-components';

export const Nav = styled.nav`
  height: 80px;
  // background: rgb(255,255,255);
  // background: linear-gradient(180deg, rgba(255,255,255,.9) 0%, rgba(255,255,255,.8) 50%, rgba(255,255,255,0) 100%);;
  z-index: 1000;
  width: calc(100%);
  position: fixed;
  top: 0;
  left:0;
  transition: transform 0.4s;

  & > div {
    
  }

  & > div > div {
    transition: all .2s ease-in;

    background-color: ${({ colored }) => colored ? '#fff' : 'none'};
    padding: 20px 34px;
    display: flex;
    justify-content: space-between;

    @media (max-width: 600px) {
      padding: 8px 8px;
    }
  }
`;

export const Navigation = styled.ul`
  & > li {
    display: inline;
    font-size: 1em;
    margin: 24px 20px;
    font-weight: 500;
    font-family: Thicccboi, sans-serif;

    & > a {
      text-decoration: none;
      color: ${({ theme }) => theme.palatte.text.default};
    }
  }
`;

export const LogoWrapper = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.palatte.text.default};

  & > div {
    display: flex;
    
    & > div {
      margin: 2px 16px;
      text-decoration: none;
      h1 {
        margin: 0px;
        font-size: 27px;
        font-weight: 500;
        letter-spacing: 2px;
      }
    }
  }

  @media (max-width: 600px) {
    transform: scale(0.8)
    margin-left: -12px;
  }
`

export const ShowMenuIcon = styled.a`
  display: none;
 
  &:hover > {
    cursor: pointer;
  }
 
  @media (max-width: 600px) {
    display: flex;
    align-items: center;
    margin-left: auto;
  }
`

export const MenuListWrapper = styled.a`
  display: flex;
  align-items: center;

  @media (max-width: 600px) {
    & > .contact-us {
      display: none;
    }
  }

  & > .contact-us {
    border: none;
    font-size: 16px;
    padding: 0.76rem 2rem;
    margin: 0.5rem 0rem 0.5rem 1.5rem;
    background: #3D6BFB;
    color: #fff;
    border-radius: 8px;
    font-family: Thicccboi, sans-serif;

    -webkit-transition: background-color .2s ease-out;
    -moz-transition: background-color .2s ease-out;
    -o-transition: background-color .2s ease-out;
    transition: background-color .2s ease-out;
  }

  & > .contact-us:hover {
    background: #DF7356;
    cursor: pointer;
  }
`

export const MenuList = styled.div`
  display: block;

  & > .close {
    display: none;
  }

  & > .close:hover {
    cursor: pointer;
  }

  @media (max-width: 600px) {
    height: calc(100vh - 78px);
    width: 100vw;
    top: 78px;
    left: 0px;
    background-color: #fff;
    position: absolute; 
    display: flex;
    justify-content: center;
    align-items: center;
    
    & > div > ul {
      padding-left: 0px;  
    }

    & > div > ul > li {
      display: block;
      text-align: center;
      line-height: 56px;
      font-size: 32px;
    }

    & > .close {
      position: absolute;
      top: 24px;
      display: block;
      right: 24px;
    }
  }
`;

export const MenuIconWrapper = styled.div`

  .menu-icon-wrapper {
    background: #fff;
    height: 48px;
    width: 48px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;

  .menu-icon {
    width: 24px;
    height: 16px;
    position: relative;
    transform: rotate(0deg);
    transition: .5s ease-in-out;
    cursor: pointer;

    span {
      display: block;
      position: absolute;
      height: 3px;
      width: 100%;
      background: ${({ theme }) => theme.palatte.text.default};
      border-radius: 9px;
      opacity: 1;
      left: 0;
      transform: rotate(0deg);
      transition: .25s ease-in-out;
    }

    span:nth-child(1) {
      top: 0px;
    }
    
    span:nth-child(2) {
      top: 8px;
    }
    
    span:nth-child(3) {
      top: 16px;
    }
  }

  .menu-icon.open {
    span:nth-child(1) {
      top: 4px;
      transform: rotate(135deg);
    }
    span:nth-child(2) {
      opacity: 0;
      left: -60px;
    }
    span:nth-child(3) {
      top: 4px;
      transform: rotate(-135deg);
    }
  }
}
`;

export const HomeHeaderWrapper = styled.div`
  margin-top: 110px;
  height: 110px;
  background-color: #fff;
  margin-left: 32px;

  & > h1 {
    font-family: Thicccboi, sans-serif;
    font-size: 32px;
    font-weight: 500;
  }
`;